import React from "react"
import Container from 'react-bootstrap/Container'
import Layout from "../components/Layout/Layout"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import './contact.scss'

const ContactPage = () => (
  <Layout
    title="Message Sucessfully Sent | Guiding Digital"
    description="Thank you for sending us a message!  We'll respond as soon as possible.">
    <HeroBanner
      headline="Contact Us"
      description="Give us a yell to see how we can help with your next project and grow your digital presence."
      className="contactHero"
    />

    <Container className="text-center pb-5">
        <h2>Message Successfully Sent</h2>
        Thank you for sending us a message!  We'll respond as soon as possible.
    </Container>

  </Layout>
)

export default ContactPage